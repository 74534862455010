import { Injectable } from '@angular/core';
import { ApiGatewayService, authenticationSelectors } from '@wam/authentication';
import { Observable } from 'rxjs';
import { State, User } from '@wam/shared';
import { select, Store } from '@ngrx/store';
import { switchMap, take } from 'rxjs/operators';
import { EnrollmentType, Program } from '@wap/enrollment-v2';
import { FormSubmission } from './programs-claims/state/programs-claims.model';
import { toISODate } from '@app/constants';

export const UPSTART_ORG = 'upstart';

@Injectable({ providedIn: 'root' })
export class ProgramsDataService {
  constructor(
    private apiGatewayService: ApiGatewayService,
    private store: Store<State>,
  ) {}

  getPrograms(
    formSubmission: FormSubmission,
    programEnrollmentId: string,
    enrollmentType?: EnrollmentType,
  ): Observable<Program[]> {
    const birthDate = new Date(formSubmission.programChildBirthdateField);

    let parameters = `zip=${formSubmission.programChildZipCode}&state=${
      formSubmission.programChildPhysicalStateField
    }&birthDate=${toISODate(birthDate)}&programEnrollmentId=${programEnrollmentId}`;

    parameters += formSubmission.programChildInvitationCode
      ? `&referralCode=${formSubmission.programChildInvitationCode}`
      : '';

    if (!!enrollmentType) {
      parameters += `&enrollmentType=${enrollmentType}`;
    }

    return this.withUser().pipe(
      switchMap((user: User) => {
        return this.apiGatewayService.get<Program[]>(
          `enrollment-v2/v2/apps/${user.application}/orgs/${UPSTART_ORG}/programs?${parameters}`,
        );
      }),
    );
  }

  getSubmission(
    formId: string,
    version: string,
    submission,
  ): Observable<{ formSubmission: object }> {
    return this.withUser().pipe(
      switchMap((user: User) => {
        return this.apiGatewayService.get<{ formSubmission: object }>(
          `enrollment-v2/v2/apps/${user.application}/orgs/${UPSTART_ORG}/forms/${formId}/versions/${version}/submissions/${submission}`,
        );
      }),
    );
  }

  deleteSubmission(formId: string, version: string, submissionId: string) {
    return this.withUser().pipe(
      switchMap((user: User) => {
        return this.apiGatewayService.delete(
          `enrollment-v2/v2/apps/${user.application}/orgs/${UPSTART_ORG}/forms/${formId}/versions/${version}/submissions/${submissionId}`,
        );
      }),
    );
  }

  private withUser(): Observable<User> {
    return this.store.pipe(select(authenticationSelectors.getCurrentUser), take(1));
  }
}
